<template>
  <b-card bg-variant="light"
          header="Consents"
          header-bg-variant="dark"
          header-text-variant="white">
    <b-tabs v-model="selected_card"
            card>

      <b-tab lazy
             title="User list">
        <users_consents_list :fields="user_consents_fields"></users_consents_list>
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
  import users_consents_list from '@/components/operational/consents/users_consents_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'consents_dashboard',
    components: { users_consents_list },
    data() {
      return {
        selected_card: 1,
        user_consents_fields: [
          ordinalNumber,
          {
            key: 'first_name',
            label: 'Name',
          },
          {
            key: 'last_name',
            label: 'Surname',
          },
          {
            key: 'email',
            label: 'Email',
          },
          {
            key: 'consent_data.register.states.state',
            label: 'Registration consent (true/false)',
          },
          {
            key: 'consent_data.team_share.states.state',
            label: 'Team consent (true/false)',
          },
          {
            key: 'consent_data.promotion.states.state',
            label: 'Promotion consent (true/false)',
          },
          {
            key: 'consent_data.communication.states.state',
            label: 'Communication consent',
          },
          {
            key: 'consent_data.wishes.states.state',
            label: 'Wishes consent',
          },
          { key: 'actions' },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
